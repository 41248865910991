import React from 'react';
import Layout from '../../../components/Layout';
import { Container, Row, Column } from '../../../components/Grid';
import Section from '../../../components/Section';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Video from '../../../components/Video';

const TeshaAlstonDampier = () => (
  <Layout
    title="3M Senior Quality Engineer | Tesha Alston Dampier"
    className="science-starters careers has-video"
  >
    {/* Intro Title and Video */}
    <Section>
      <Container>
        {/* Breadcrumbs */}
        <ul className="breadcrumbs no-hero">
          <li className="breadcrumb">
            <Link to="../../getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb">
            <Link to="../../science-at-work">Science at Work</Link>
          </li>
          <li className="breadcrumb active">Tesha Alston Dampier</li>
        </ul>
        <Row>
          <Column size={10} offset={1} className="no-hero">
            <h1 className="page-title">3M Senior Quality Engineer</h1>
            {/* Video Container */}
            <div className="video-container__with-pager">
              <Link to="../jeff-emslander" className="previous">
                <Icon name="arrowleftgray" />
              </Link>
              <Video
                guidSrc="a2ffe6c7-9374-49ca-bf24-b5b6fa721ee1"
                className="de-vid"
              ></Video>
              <Link to="../sara-frisco" className="next">
                <Icon name="arrowrightgray" />
              </Link>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Main Content */}
    <Section className="pb-6">
      <Container>
        <Row>
          <Column size={7} offset={1} className="career-left">
            <h2>Improving Lives in the Field of Biopharmaceuticals</h2>
            <p>
              As a Senior Quality Engineer, Tesha and her team develop filters
              that are an integral part of the purification process of drug
              manufacturing, from vaccines to blood purification. Tesha has been
              able to give back to her community while expanding her own
              knowledge and skills through a variety of 3M science and
              enrichment programs.
            </p>
            <h3>3 Things I Love About My Job</h3>
            <ol>
              <li>
                <strong className="text-bold">
                  Playing a key role in the life-changing field of
                  biopharmaceuticals.
                </strong>
                <br />
                Tesha is part of a dynamic cross-functional team that makes
                purification filters that are essential to creating safe,
                lifesaving biopharmaceuticals.
              </li>
              <li>
                <strong className="text-bold">
                  Professional flexibility to volunteer and give back to my
                  community.
                </strong>
                <br /> As a 3M Mentor and volunteer working with nonprofits
                around the nation, Tesha is passionate about giving back to her
                community and inspiring tomorrow’s leaders in science and
                medicine.
              </li>
              <li>
                <strong className="text-bold">
                  Being empowered to continually build my knowledge and expand
                  my expertise.
                </strong>
                <br /> Professional development opportunities are an important
                part of Tesha’s growth as a scientific problem-solver and mentor
                to others.
              </li>
            </ol>
          </Column>
          <Column size={4}>
            <div className="global__profile-box box-shadow">
              <div className="global__profile-image">
                <Image filename="Tesha-AlstonDampier-mentor-quote.png" />
              </div>
              <div className="global__profile-content">
                <h4 className="quote">
                  “I love having the flexibility to volunteer and give back to
                  my community.”
                </h4>
                <h3>Tesha Alston Dampier</h3>
                <p>
                  3M Senior Quality Engineer, Biopharmaceutical Purification
                  Business
                </p>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default TeshaAlstonDampier;
